<template>
  <b-row>

    <h2 class="pl-1">{{ $t('side_bar.product') }}</h2>

    <!--  BEFORE TABLE  -->
    <div class="d-flex justify-content-between col-12">
      <b-col
        md="4"
        class="my-1 px-0"
      >
        <b-form-group
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              :placeholder="$t('reusable.search_placeholder')"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                {{ $t('reusable.clear_btn') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <div
        class="my-1 float-right"
      >
        <modal-button
          ripple="rgba(113, 102, 240, 0.15)"
          size="lg"
          name="modal-main"
          :modalButton="$t('reusable.modal_add_btn')"
          @btn-emit="createNewProduct"
          @open-emit="clearData"
          :backdrop-close="true"
          :modal-title="$t('product_table.add_product_title')"
          variant="success"
        >
          <template v-slot:button @click="createNewProduct">{{ $t('reusable.modal_add_btn') }}</template>

          <template v-slot:modal-body>
            <div class="d-flex flex-wrap">

              <!--       CATEGORY ID       -->
              <infinity-scroll-select
                class="col-6 mb-2"
                v-model="categoryId"
                apiUrl="products.fetchCategory"
                :label="$t('product_table.category_id')"
              />

              <!--       BRAND ID       -->
              <infinity-scroll-select
                class="col-6 mb-2"
                v-model="brandId"
                apiUrl="products.fetchBrand"
                :label="$t('product_table.brand_id')"
              />

              <!--       MODEL NUMBER       -->
              <b-col cols="12">
                <b-form-group
                  :label="$t('product_table.model_name')"
                  label-for="defaultLabel"
                >
                  <b-form-input
                    v-model="model_number"
                    id="defaultLabel"
                    size="lg"
                    placeholder="model-number"
                  />
                </b-form-group>
              </b-col>

              <!--       SKU       -->
              <b-col cols="6">
                <b-form-group
                  label="SKU"
                  label-for="defaultLabel"
                >
                  <b-form-input
                    v-model="sku"
                    id="defaultLabel"
                    size="lg"
                    placeholder="sku"
                  />
                </b-form-group>
              </b-col>

              <!--       DATE EOL       -->
              <b-col cols="6">
                <b-form-group
                  :label="$t('product_table.date_eol')"
                >
                  <b-input-group-append>
                    <b-form-datepicker
                      aria-controls="example-input"
                      locale="en-US"
                      v-model="date_eol"
                      class="mb-2"
                    />
                  </b-input-group-append>
                </b-form-group>
              </b-col>

              <!--    DRAG AND DROP     -->
              <b-col cols="12">

                <VueFileAgent
                  ref="vueFileAgent"
                  :uploadUrl="uploadUrl"
                  v-model="images_list"
                  :multiple="true"
                  :deletable="true"
                  :accept="'image/*,.zip'"
                  :maxSize="'2MB'"
                  :maxFiles="15"
                  :helpText="$t('reusable.file_upload_text')"
                  @select="filesSelected($event)"
                  @beforedelete="onBeforeDelete($event)"
                  @delete="fileDeleted($event)"
                >

                </VueFileAgent>
              </b-col>

            </div>
          </template>
        </modal-button>

      </div>
    </div>

    <!--  TABLE  -->
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        sort-icon-left
        :busy="isBusy"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template v-slot:head(is_deleted)="data">
          <b-form-select
            size="md"
            v-model="status"
            class="w-100"
          >
            <template #first>
              <option
                selected
                :value="null"
              >
                Выберите статус
              </option>
            </template>
            <option
              v-for="(status, index) in statuses"
              :key="index"
              :value="status"
            >
              {{ status }}
            </option>
          </b-form-select>
        </template>

        <template #cell(name)="data">
          {{ data.item.name }}
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-1">{{ $t('reusable.isBusy') }}</strong>
          </div>
        </template>

        <template #cell(is_active)="data">
          <b-form-checkbox
            class="custom-control-success"
            name="check-button"
            v-model="data.item.is_active"
            @change="updateActive(data.item.id,$event)"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon"/>
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon"/>
            </span>
          </b-form-checkbox>
        </template>

        <template #cell(images_list)="data">
          <div style="width: 80px; height: 80px" v-if="data.item.images_list.length > 0">
            <img style="object-fit: cover; width: 100%; height: 100%" :src="data.item.images_list[0].w_500">
          </div>
        </template>

        <template #cell(crud_row)="data">
          <div class="d-flex float-right">

            <b-dropdown
              no-caret
              id="table-action-dropdown"
              variant="link"
              size="md"
            >
              <template
                #button-content
                class="p-0"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="p-0"
                />
              </template>

              <!--   PREVIEW BUTTON   -->
              <b-dropdown-item
                class="px-0"
              >
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="xl"
                  :name="`modal-preview-${data.item.id}`"
                  :backdrop-close="true"
                  :modalButton="$t('region_table.country_edit_modal_title')"
                  :id="data.item.id"
                  :hide-footer="true"
                  :modal-title="$t('reusable.preview_btn')"
                  :disableButton="!editAllow"
                  variant="flat-primary"
                  @open-emit="getProductById(data.item.id)"
                  @btn-emit="updateProduct(data.item.id, data.item)"
                >
                  <template
                    v-slot:button
                    style="width: 100% !important;"
                  >
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="EyeIcon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.preview_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>

                    <div class="d-flex flex-wrap">

                      <!--       CATEGORY ID       -->
                      <infinity-scroll-select
                        class="col-6 mb-2"
                        v-model="categoryId"
                        :disabled="!editAllow"
                        apiUrl="products.fetchCategory"
                        :label="$t('product_table.category_id')"
                      />

                      <!--       BRAND ID       -->
                      <infinity-scroll-select
                        class="col-6 mb-2"
                        v-model="brandId"
                        :disabled="!editAllow"
                        apiUrl="products.fetchBrand"
                        :label="$t('product_table.brand_id')"
                      />

                      <!--       MODEL NUMBER       -->
                      <b-col cols="4">
                        <b-form-group
                          :label="$t('product_table.model_name')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="model_number"
                            id="defaultLabel"
                            :disabled="!editAllow"
                            size="lg"
                            placeholder="model-number"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="4">
                        <b-form-group
                          :label="$t('region_table.created_at')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="createdAt"
                            id="defaultLabel"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('region_table.created_at')"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="4">
                        <b-form-group
                          :label="$t('region_table.updated_at')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="updatedAt"
                            id="defaultLabel"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('region_table.updated_at')"
                          />
                        </b-form-group>
                      </b-col>

                      <!--    DRAG AND DROP     -->
                      <b-col cols="12">
                        <VueFileAgent
                          ref="vueFileAgent"
                          :uploadUrl="uploadUrl"
                          v-model="images_list"
                          :multiple="true"
                          :deletable="true"
                          :accept="'image/*,.zip'"
                          :maxSize="'2MB'"
                          :disabled="!editAllow"
                          :maxFiles="15"
                          :helpText="'Choose images or zip files'"
                          @select="filesSelected($event)"
                          @beforedelete="onBeforeDelete($event)"
                          @delete="fileDeleted($event)"
                        >
                        </VueFileAgent>
                      </b-col>

                    </div>

                  </template>


                </modal-button>
              </b-dropdown-item>

              <!--  EDIT BUTTON  -->
              <b-dropdown-item
                class="px-0"
              >
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="lg"
                  :name="`modal-edit-${data.item.id}`"
                  :modalButton="$t('region_table.country_edit_modal_title')"
                  :id="data.item.id"
                  :modal-title="$t('region_table.country_edit_modal_title')"
                  variant="flat-warning"
                  :backdrop-close="true"
                  @open-emit="getProductById(data.item.id)"
                  @btn-emit="updateProduct(data.item.id, data.item)"
                >
                  <template
                    v-slot:button
                    style="width: 100% !important;"
                  >
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="Edit2Icon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.modal_edit_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>
                    <div class="d-flex flex-wrap">

                      <!--       CATEGORY ID       -->
                      <infinity-scroll-select
                        class="col-6 mb-2"
                        v-model="categoryId"
                        apiUrl="products.fetchCategory"
                        :label="$t('product_table.category_id')"
                      />

                      <!--       BRAND ID       -->
                      <infinity-scroll-select
                        class="col-6 mb-2"
                        v-model="brandId"
                        apiUrl="products.fetchBrand"
                        :label="$t('product_table.brand_id')"
                      />

                      <!--       MODEL NUMBER       -->
                      <b-col cols="12">
                        <b-form-group
                          :label="$t('product_table.model_name')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="model_number"
                            id="defaultLabel"
                            size="lg"
                            placeholder="model-number"
                          />
                        </b-form-group>
                      </b-col>

                      <!--    DRAG AND DROP     -->
                      <b-col cols="12">
                        <VueFileAgent
                          ref="vueFileAgent"
                          :uploadUrl="uploadUrl"
                          v-model="images_list"
                          :multiple="true"
                          :deletable="true"
                          :accept="'image/*,.zip'"
                          :maxSize="'2MB'"
                          :maxFiles="15"
                          :helpText="'Choose images or zip files'"
                          @select="filesSelected($event)"
                          @beforedelete="onBeforeDelete($event)"
                          @delete="fileDeleted($event)"
                        >
                        </VueFileAgent>
                      </b-col>

                    </div>
                  </template>

                </modal-button>
              </b-dropdown-item>

              <!--  VARIATIONS BUTTON  -->
              <b-dropdown-item>
                <button
                  class="variables__button px-0"
                  @click.prevent="$router.push({name:'variation', params: {id: data.item.id}})"
                >
                  <feather-icon
                    icon="DatabaseIcon"
                    size="21"
                    style="margin-right: 6px"
                  />
                  {{ $t('reusable.variations') }}
                </button>
              </b-dropdown-item>

              <!--  DELETE BUTTON  -->
              <b-dropdown-item>
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="sm"
                  :name="`modal-delete-${data.item.id}`"
                  :modalButton="$t('region_table.country_delete_modal_title')"
                  :modal-title="$t('region_table.country_delete_modal_title')"
                  variant="flat-danger"
                  :id="data.item.id"
                  @btn-emit="deleteProduct"
                >
                  <template v-slot:button class="w-100">
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="Trash2Icon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.modal_delete_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>
                    <div class="my-2">
                      {{ $t('product_table.delete_product_text') }}
                    </div>
                  </template>

                </modal-button>
              </b-dropdown-item>

            </b-dropdown>

          </div>
        </template>

      </b-table>
    </b-col>

    <!--  PAGINATION  -->
    <b-col
      cols="12"
    >
      <b-pagination
        v-if="showPagination"
        v-model="pagination.current"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        align="center"
        size="sm"
        class="mb-5 mt-2"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BBadge,
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BLink,
  BTable,
  BSpinner
} from 'bootstrap-vue'
import ModalButton from '@/views/ui/modals/ModalButton.vue'
import api from '@/services/api'
import InfinityScrollSelect from '@/views/ui/infinity-scroll/InfinityScrollSelect'
import InfinityScrollSelectSpec from '@/views/ui/infinity-scroll/InfinityScrollSelectSpec'
import Compressor from 'compressorjs'

export default {
  components: {
    BTable,
    BBadge,
    BRow,
    BLink,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormFile,
    BFormDatepicker,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    ModalButton,
    InfinityScrollSelect,
    InfinityScrollSelectSpec,
  },
  data() {
    return {
      createdAt: '',
      updatedAt: '',
      images: [],
      maxImage: 13,
      editAllow: false,
      categoryId: null,
      categoryApi: {},
      brandId: null,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      status: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      statuses: [true, false],
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'model_number',
          label: this.$t('product_table.product_name'),
          sortable: true,
        },
        {
          key: 'sku',
          label: this.$t('product_table.sku'),
        },
        {
          key: 'date_eol',
          label: this.$t('product_table.date_eol'),
        },
        {
          key: 'images_list',
          label: this.$t('product_table.image_list'),
        },
        {
          key: 'is_active',
          label: this.$t('shops_table.is_active'),
        },
        {
          key: 'created_at',
          label: this.$t('region_table.created_at'),
        },
        {
          key: 'updated_at',
          label: this.$t('region_table.updated_at'),
        },
        {
          key: 'crud_row',
          label: ' ',
        },
      ],
      variant: '',
      items: [],
      model_number: '',
      sku: '',
      date_eol: '',
      formattedDate: '',
      selectedDate: '',
      images_list: [],
      isBusy: false,
      image: [],
      pagination: {
        current: 1,
        total: 1,
        per_page: 5
      },
      fileRecords: [],
      uploadUrl: '',
      uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
      fileRecordsForUpload: [],
      itemId: null,
      specifications: null,
      specOption: {},
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        this.pagination.current = query.page
        this.fetchProductList()
      },
      deep: true,
      immediate: true
    },
    'pagination.current': {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        })
      },
    },
    categoryId(value) {
      if (value) {
        this.getCategoryById(value.id)
      } else {
        this.specifications = {}
      }
    },
  },

  computed: {
    rows() {
      return this.items.length
    },

    query() {
      return Object.assign({}, this.$route.query)
    },

    hasItems() {
      return this.items.length > 0
    },

    showPagination() {
      return this.hasItems && !this.isBusy
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key,
        }))
    },
  },

  methods: {

    updateActive(id, active) {
      api.products.updateProductActive(id, active ? 1 : 0)
        .then(res => {
          console.log(res)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {

        })
    },

    srcToFile(src, fileName, mimeType) {
      const token = localStorage.getItem('accessToken')
      return (fetch(src, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': token
            }
          }
        )
          .then(function (res) {
            return res.arrayBuffer()
          })
          .then(function (buf) {
            return new File([buf], fileName, { type: mimeType })
          })
      )
    },

    deleteUploadedFile: function (id, fileRecord) {
      api.products.deleteProductFile(this.itemId, this.images_list.indexOf(fileRecord))
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord)
    },

    filesSelected: function (fileRecordsNewlySelected) {
      let validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error)
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
    },

    onBeforeDelete: function (fileRecord) {
      let i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
        this.deleteUploadedFile(fileRecord)
      } else {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord) // will trigger 'delete' event
      }
    },

    async fileDeleted(fileRecord) {
      const deleteFileRecord = this.images_list.findIndex(image => image.url === fileRecord.url)
      await api.products.deleteProductFile(this.itemId, deleteFileRecord)
      // Using the default uploader. You may use another uploader instead.
      await this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord)
      let i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      }
    },

    replaceRouter(query) {
      this.$router.replace({ query })
        .catch(() => {
        })
    },

    async fetchProductList() {
      this.isBusy = true
      const data = {
        page: this.$route.query.page
      }
      await api.products.fetchProduct(data)
        .then(res => {
          this.items = res.data.data
          this.pagination.per_page = res.data.meta.per_page
          this.pagination.total = res.data.meta.total
          this.pagination.current = res.data.meta.current_page
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isBusy = false
        })
    },

    async getProductById(id) {
      this.itemId = id
      this.images_list = []
      const data = {
        with: {
          0: 'category',
          1: 'brand',
        }
      }
      this.editAllow = false
      await api.products.fetchOneProduct(id, data)
        .then(async res => {
          this.createdAt = res.data.created_at
          this.updatedAt = res.data.updated_at
          this.categoryId = res.data.category
          this.brandId = res.data.brand
          this.model_number = res.data.model_number
          this.sku = res.data.sku
          this.date_eol = res.data.date_eol
          const [day, month, year] = this.date_eol.split('.')
          this.date_eol = [year, month, day].join('-')

          for (let i = 0; i < res.data.images_list.length; i++) {
            const image = res.data.images_list[i].original
            this.images_list = [...this.images_list, {
              name: 'image.jpg',
              size: 0,
              type: 'image/jpg',
              url: image,
              src: image
            }]
          }

        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {

        })
    },

    compressImage(file) {
      return new Compressor(file, {
        maxWidth: 1200,
        quality: 0.8,
        convertSize: 5000000,
        success: (result) => {
          return new File([result], result.name, {
            type: result.type,
            lastModified: Date.now(),
          })
        }
      })
    },

    clearData() {
      this.images_list = []
      this.categoryId = null
      this.brandId = null
      this.date_eol = null
      this.sku = null
      this.model_number = ''
    },

    getCategoryById(id) {
      const data = {
        with: {
          0: 'specifications.options'
        }
      }
      api.products.fetchOneCategory(id, data)
        .then(res => {
          this.specifications = res.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {

        })
    },

    createNewProduct() {
      const formData = new FormData()

      const [year, month, day] = this.date_eol.split('-')
      this.date_eol = [day, month, year].join('.')

      for (let i = 0; i < this.images_list.length; i++) {
        formData.append('images_list[' + i + ']', this.images_list[i].file)
      }

      formData.append('category_id', this.categoryId.id)
      formData.append('brand_id', this.brandId.id)
      formData.append('model_number', this.model_number)
      formData.append('date_eol', this.date_eol)
      formData.append('sku', this.sku)

      api.products.createProduct(formData)
        .then(res => {
          console.log(res)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchProductList()
        })
    },

    deleteProduct(id) {
      api.products.deleteProduct(id)
        .then(res => {
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchProductList()
        })
    },

    updateProduct(id, data) {
      const formData = new FormData()

      const [year, month, day] = this.date_eol.split('-')
      this.date_eol = [day, month, year].join('.')

      for (let i = 0; i < this.images_list.length; i++) {
        if (this.images_list[i].file && this.images_list[i].file.webkitRelativePath == '') {
          formData.append('images_list[' + i + ']', this.images_list[i].file)
        }
      }

      formData.append('category_id', this.categoryId.id)
      formData.append('brand_id', this.brandId.id)
      formData.append('model_number', this.model_number)
      formData.append('date_eol', this.date_eol)
      formData.append('sku', this.sku)
      api.products.updateProduct(id, formData)
        .then(res => {
          this.categoryId = res.data.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.images_list = []
          this.fetchProductList()
        })
    },

    checkLocales(value) {
      if (localStorage.lang === 'ru') {
        return value.ru
      }
      return value.uz
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },

    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>

<style lang="scss">

[dir] .dropdown-item {
  padding: 0;
}

.image-container {
  width: 100% !important;
  background: #fff !important;
}

body.dark-layout .image-container {
  background: #283046 !important;
}

.image-list-container {
  max-width: 100% !important;
}

.image-list-item {
  width: 50px !important;
  height: 50px !important;
}

.image-list-container .image-list-item .show-img {
  max-width: 40px !important;
  max-height: 40px !important;
}

#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

//ul {
//  list-style-type: none;
//  padding: 0;
//}
//
//li {
//  display: inline-block;
//margin: 0 10px;
//}
//
//a {
//  color: #42b983;
//}

.image-bottom-left {
  display: none !important;
}

body.dark-layout .image-icon-edit {
  width: 24px !important;
  height: 24px !important;
  fill: #fff !important;
}

body.dark-layout .image-icon-delete {
  width: 24px !important;
  height: 24px !important;
  fill: #fff !important;
}

body.dark-layout .add-image-svg {
  width: 24px !important;
  height: 24px !important;
  fill: #fff !important;
}

.image-icon-edit {
  width: 24px !important;
  height: 24px !important;
  fill: #000 !important;
}

.image-icon-delete {
  width: 24px !important;
  height: 24px !important;
  fill: #000 !important;
}

.add-image-svg {
  width: 24px !important;
  height: 24px !important;
  fill: #000 !important;
}

#example-datepicker__dialog_ {
  z-index: 1000;
}

.variables__button {
  width: 100%;
  padding: 0.786rem 1.5rem !important;
  border: none;
  background: transparent;
  color: green;
  font-weight: 500;
  text-align: left;
}

</style>
